import React, { useState } from "react";
import "./contact.css";
import Header from "../Header/header";
import Footer from "../Footer/footer";

const locations = [
  {
    city: "United States",
    address: "8 The Green Ste R · Dover, DE 19901",
    phoneNumber: "+1 240 525 0547",
    email: "hr@jobways.com",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1637.5372693701577!2d-75.5249159!3d39.1565906!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c7657e0f270c79%3A0x3cc77cd9d14827e8!2s8%20The%20Green%2C%20Dover%2C%20DE%2019901%2C%20USA!5e1!3m2!1sen!2sin!4v1704897311255!5m2!1sen!2sin",
  },
  {
    city: "Canada",
    address: "30 Wellington St W, Toronto, Ontario M5L 1E2, CA",
    phoneNumber: "+1 437 291 3517",
    email: "hr.canada@jobways.com",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1528.0904969577548!2d-79.3802161!3d43.6477588!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb2d3ed917f5%3A0xad7c1646a50e5e55!2s30%20Wellington%20St%20W%2C%20Toronto%2C%20ON%20M5L%201E2%2C%20Canada!5e1!3m2!1sen!2sin!4v1704898984265!5m2!1sen!2sin",
  },
  {
    city: "United Kingdom",
    address: "UK	128 City Road, London, EC1V 2NX, United Kingdom",
    phoneNumber: "+44 20 3870 3047",
    email: "hr.uk@jobways.com",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d656.9201546123152!2d-0.0888571!3d51.527212!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ca671a6cfb7%3A0x9320c88f0a927f3f!2s128%20City%20Rd%2C%20London%20EC1V%202NX%2C%20UK!5e1!3m2!1sen!2sin!4v1704899056844!5m2!1sen!2sin",
  },
  {
    city: "India",
    address:
      "Unit-NO: 7-140/2,E 5, NAGENDRA NAGAR, SCIENTIST COLONY, HABSIGUDA, HYDERABAD- 500 007",
    phoneNumber: "+91 70134 96618",
    email: "hr.india@jobways.com",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d237.92794854114504!2d78.5434038!3d17.4191202!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99747f2c917b%3A0x276db082437dbb00!2sBDI%20INDIA%20Pvt%20ltd%20Hyderabad!5e0!3m2!1sen!2sin!4v1704899088577!5m2!1sen!2sin",
  },
  {
    city: "Ethiopia",
    address:
      "Afomi Building First Floor, Addis Ababa, Bole, Behind Friendship City Center, Addis Ababa, Ethiopia",
    phoneNumber: "+251 97 070 7189",
    email: "hr.ethiopia@jobways.com",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3940.810062192337!2d38.7845619!3d8.9896184!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b84fb99570403%3A0x8a21e3ebfc8b4fc5!2zRnJpZW5kc2hpcCBCdXNpbmVzcyBDZW50ZXIgKOGNjeGIrOGKleGLteGIuuGNlSDhi6jhjIjhiaDhi6sg4Yib4Yql4Yqo4YiNKQ!5e0!3m2!1sen!2sin!4v1704899121659!5m2!1sen!2sin",
  },
  // Add more locations here...
];

const ContactUsPage = () => {
  const [src, setsrc] = useState(
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1637.5372693701577!2d-75.5249159!3d39.1565906!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c7657e0f270c79%3A0x3cc77cd9d14827e8!2s8%20The%20Green%2C%20Dover%2C%20DE%2019901%2C%20USA!5e1!3m2!1sen!2sin!4v1704897311255!5m2!1sen!2sin"
  );
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = process.env.REACT_APP_HOST;

    try {
      const response = await fetch(`${url}comment/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      setFormData({
        ...formData,
        name: "",
        email: "",
        message: "",
        number: "",
      });

      if (response.ok) {
        // setFormData({
        //   ...formData,
        //   name: "",
        //   email: "",
        //   message: "",
        //   number: "",
        // });
      } else {
        alert("Error sending message");
      }
    } catch (error) {
      // alert("An error occurred");
    }
  };

  const handlemap = (map) => {
    setsrc(map);
  };

  return (
    <>
      <Header />

      <div className="contact-page-container">
        <div className="contact-form-container">
          <h2>Send us a message</h2>
          <form onSubmit={handleSubmit}>
            <input
              className="cinput"
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              className="cinput"
              type="tel"
              name="number"
              placeholder="Phone Number"
              value={formData.number}
              onChange={handleChange}
            />
            <input
              className="cinput"
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            <textarea
              className="text"
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <button type="submit" className="cbutton">
              Submit
            </button>
          </form>
        </div>

        <div className="locations-container">
          <h1 className="heading">OUR LOCATIONS</h1>
          {locations.map((location, index) => (
            <div
              key={index}
              className="location"
              onClick={() => {
                handlemap(location.map);
              }}
            >
              <h2>{location.city}</h2>
              <p>
                <span className="icon">📍</span>
                {location.address}
              </p>
              <p>
                <span className="icon">📞</span>
                {location.phoneNumber}
              </p>
              <p>
                <span className="icon">✉️</span>
                {location.email}
              </p>
            </div>
          ))}
        </div>
      </div>
      <h1 className="heading">Click on Location to get the Maps</h1>
      <div className="google-map-container">
        <iframe
          src={src}
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Map"
        ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default ContactUsPage;
